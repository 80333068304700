import React, { useEffect, useMemo, useState } from "react";
import { Grid, Box, Button } from "@mui/material";
import { Person, Delete } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { selectFacilityId } from "../../../store/slices/authSlice/AuthSlice";
import {
  useGetDiningAreaByIdQuery,
  useGetDiningAreaTableByIdQuery,
  useGetResidentDetailQuery,
  useMoveResidentToReserveMutation,
  useAddResidentPhotoMutation,
} from "../../../store/apis/ResidentApis";
import { useNavigate, useSearchParams } from "react-router-dom";
import "./ResidentDetails.css";
import { useGetFacilityDietsQuery } from "../../../store/apis/DietsApi";
import { ApiFetchImage } from "../../../utils/ApiCall";
import { BaseButton } from "components/shared/BaseButton";
import TableSideInformation from "../residentDetailsSections/TableSideInformation";
import ResidentDetailsCardSection from "../residentDetailsSections/ResdientDetailsCardSection";
import DietInformationSection from "../residentDetailsSections/DietInformationSection";
import DiningInformationSection from "../residentDetailsSections/DiningInformationSection";
import WeightMetricsSection from "../residentDetailsSections/WeightMetricsSection";
import { Assignment } from "@mui/icons-material";
import DeleteModal from "../../shared/deleteModal/DeleteModal";

export default function ResidentDetails() {
  const facilityId = useSelector(selectFacilityId);
  const [searchParams] = useSearchParams();
  const [residentId] = useState(searchParams.get("id"));
  const [resident, setResident] = useState({});
  const [decliningBalance, setDecliningBalance] = useState(false);
  const [photoEnabled, setPhotoEnabled] = useState(false);
  const [photo, setPhoto] = useState();
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();

  const [
    updatePhoto,
    { isSuccess: isPhotoUpdateSuccess, isLoading: isPhotoUpdateLoading },
  ] = useAddResidentPhotoMutation();

  const isPhotoSuccess = isPhotoUpdateSuccess;
  const isPhotoLoading = isPhotoUpdateLoading;

  const { data: residentDetails, isError: isError } = useGetResidentDetailQuery(
    {
      residentId: residentId,
      facilityId: facilityId,
    },
    {
      skip: !residentId || !facilityId,
      refetchOnMountOrArgChange: true,
    }
  );

  const [
    moveToReserved,
    { isSuccess: isMoveToReserveSuccess, isLoading: isMoveLoading },
  ] = useMoveResidentToReserveMutation();

  const { data: facilityDiets } = useGetFacilityDietsQuery(
    {
      facilityId: facilityId,
      isTexture: false,
    },
    {
      skip: !facilityId,
    }
  );

  const { data: facilityTextures } = useGetFacilityDietsQuery(
    {
      facilityId: facilityId,
      isTexture: true,
    },
    {
      skip: !facilityId,
    }
  );

  const { data: breakfastDiningArea } = useGetDiningAreaByIdQuery(
    resident.breakfastDiningAreaId,
    { skip: !resident.breakfastDiningAreaId }
  );

  const { data: lunchDiningArea } = useGetDiningAreaByIdQuery(
    resident.lunchDiningAreaId,
    { skip: !resident.lunchDiningAreaId }
  );

  const { data: supperDiningArea } = useGetDiningAreaByIdQuery(
    resident.supperDiningAreaId,
    { skip: !resident.supperDiningAreaId }
  );

  const { data: breakfastTable } = useGetDiningAreaTableByIdQuery(
    resident.breakfastTableId,
    { skip: !resident.breakfastTableId }
  );

  const { data: lunchTable } = useGetDiningAreaTableByIdQuery(
    resident.lunchTableId,
    { skip: !resident.lunchTableId }
  );

  const { data: supperTable } = useGetDiningAreaTableByIdQuery(
    resident.supperTableId,
    { skip: !resident.supperTableId }
  );

  useEffect(() => {
    if (residentDetails) {
      setResident(residentDetails.resident);
      setDecliningBalance(residentDetails.decliningBalanceEnabled);
      setPhotoEnabled(residentDetails.photoOnTrayCard);
    }
  }, [residentDetails]);

  useEffect(() => {
    if (isError) {
      navigate("/MealCard");
    }
  }, [isError]);

  function MoveResidentToReserved() {
    moveToReserved(residentId);
  }

  useEffect(() => {
    if (isMoveToReserveSuccess && !isMoveLoading) {
      navigate("/MealCard");
    }
  }, [isMoveToReserveSuccess, isMoveLoading]);

  const fetchPhoto = () => {
    ApiFetchImage(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_BASE_URL}/Residents/ResidentPhoto?residentId=${residentId}`
    )
      .then((response) => {
        if (response) {
          let reader = new FileReader();

          reader.onload = function () {
            const image = reader.result;

            setPhoto(image);
          };

          reader.readAsDataURL(response);
        } else {
          setPhoto(null);
        }
      })
      .catch((error) => {
        console.error(error);
        setPhoto(null);
      });
  };

  useMemo(() => {
    if (photoEnabled) {
      fetchPhoto();
    }
  }, [photoEnabled]);

  useEffect(() => {
    if (isPhotoSuccess && !isPhotoLoading) {
      fetchPhoto();
    }
  }, [isPhotoSuccess, isPhotoLoading]);

  //Checks for photo after possible delete
  useEffect(() => {
    if (!openModal) {
      fetchPhoto();
    }
  }, [openModal]);

  const handleFileUpload = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];

      const formData = new FormData();
      formData.append("residentId", residentId);
      formData.append("file", file);

      updatePhoto(formData);
    }
  };

  return (
    <>
      <Box>
        <Grid container spacing={0}>
          <Grid item xs={4}>
            <BaseButton
              onClick={() => MoveResidentToReserved()}
              text={" Move to Reserve"}
              endIcon={<Assignment />}
              isSubmitting={isMoveLoading}
              sx={{
                boxShadow: "0px 4px 4px 0px #00000040",
              }}
            />
          </Grid>
          <Grid
            item
            xs={8}
            container
            sx={{
              justifyContent: "flex-end",
            }}>
            <Box>
            {photoEnabled ? (
              <Button
                  component="label"
                  variant="contained"
                  color="info"
                  size="small"
                  className="baseButton"
                  sx={{
                    marginLeft: "0px !important",
                    marginRight: "10px",
                  }}
                  startIcon={<Person />}>
                  <input
                    type="file"
                    accept=".png, .jpg, ,.jpeg, .bmp"
                    hidden
                    onChange={handleFileUpload}
                  />
                  Upload Resident Photo
                </Button>
            ) : null}

            {photo ? (
              <BaseButton
                colors={"info"}
                text={"Remove Resident Photo"}
                startIcon={<Delete />}
                onClick={() => {
                  setOpenModal(true);
                }}
                sx={{
                  marginRight: "10px",
                }}
              />
            ) : null}              


            <BaseButton
              text={"Change History"}
              To={`/MealCard/residentChangeHistory?id=${residentId}`}
              endIcon={"edit_note"}
              sx={{
                backgroundColor: "var(--tealColor)",
                boxShadow: "0px 4px 4px 0px #00000040",
              }}
            />
            </Box>
          </Grid>

          <ResidentDetailsCardSection
            photo={photo}
            photoEnabled={photoEnabled}
            residentId={residentId}
            resident={resident}
            handleFileUpload={handleFileUpload}
            setOpenModal={setOpenModal}
          />
          <DietInformationSection
            residentId={residentId}
            resident={resident}
            facilityDiets={facilityDiets}
            facilityTextures={facilityTextures}
            residentDetails={residentDetails}
          />

          <DiningInformationSection
            residentId={residentId}
            resident={resident}
            breakfastDiningAreaName={breakfastDiningArea?.name}
            breakfastTableName={breakfastTable?.name}
            lunchDiningAreaName={lunchDiningArea?.name}
            lunchTableName={lunchTable?.name}
            supperDiningAreaName={supperDiningArea?.name}
            supperTableName={supperTable?.name}
          />

          {decliningBalance && (
            <TableSideInformation residentId={residentId} resident={resident} />
          )}

          <WeightMetricsSection residentId={residentId} resident={resident} />
        </Grid>
      </Box>
      {openModal && (
        <DeleteModal
          id={resident.id}
          entityName={
            resident.firstName + " " + resident.lastName + "'s profile picture"
          }
          apiPath={"deleteResidentPhoto"}
          open={openModal}
          close={() => setOpenModal(false)}
        />
      )}
    </>
  );
}
